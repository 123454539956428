<template>
  <section>
    <section class="content">
      <div v-if="userRole === 'admin'">
        <div class="dashMain">
          <VCard
            v-for="(data,index) in adminCard"
            :key="index"
            class="dashCard shadowCard"
          >
            <VCard class="dashCardTop shadow topCard">
              <div class="imgCard">
                <img
                  :lazy-src="require(`../assets/icons/dash/${data.img}.svg`)"
                  :src="require(`../assets/icons/dash/${data.img}.svg`)"
                  alt="Student"
                  draggable="false"
                >
              </div>
              <div class="span2">
                <div class="textCard">
                  <p>{{ data.title }}</p>
                </div>
              </div>
            </VCard>
            <section class="bottomCard">
              <div class="accessLevel">
                <p>{{ data.accessLevel }}</p>
                <p>Access Level</p>
              </div>
              <div class="mr-3">
                <VBtn
                  block
                  depressed
                  large
                  class="ma-0 btnDesign"
                  color="primary"
                  @click="goTo(data.link)"
                >
                  Explore
                </VBtn>
              </div>
            </section>
          </VCard>
        </div>
      </div>
      <div v-if="userRole === 'manager'">
        <div class="dashMain">
          <VCard
            v-for="(data,index) in managerCard"
            :key="index"
            class="dashCard shadowCard"
          >
            <VCard class="dashCardTop shadow topCard">
              <div class="imgCard">
                <img
                  :lazy-src="require(`../assets/icons/dash/${data.img}.svg`)"
                  :src="require(`../assets/icons/dash/${data.img}.svg`)"
                  alt="Student"
                  draggable="false"
                >
              </div>
              <div class="span2">
                <div class="textCard">
                  <p>{{ data.title }}</p>
                </div>
              </div>
            </VCard>
            <section class="bottomCard">
              <div class="accessLevel">
                <p>{{ data.accessLevel }}</p>
                <p>Access Level</p>
              </div>
              <div class="mr-3">
                <VBtn
                  block
                  depressed
                  large
                  class="ma-0 btnDesign"
                  color="primary"
                  @click="goTo(data.link)"
                >
                  Explore
                </VBtn>
              </div>
            </section>
          </VCard>
        </div>
      </div>
      <div v-if="userRole === 'teacher'">
        <div class="dashMain">
          <VCard
            v-for="(data,index) in teacherCard"
            :key="index"
            class="dashCard shadowCard"
          >
            <VCard class="dashCardTop shadow topCard">
              <div class="imgCard">
                <img
                  :lazy-src="require(`../assets/icons/dash/${data.img}.svg`)"
                  :src="require(`../assets/icons/dash/${data.img}.svg`)"
                  alt="Student"
                  draggable="false"
                >
              </div>
              <div class="span2">
                <div class="textCard">
                  <p>{{ data.title }}</p>
                </div>
              </div>
            </VCard>
            <section class="bottomCard">
              <div class="accessLevel">
                <p>{{ data.accessLevel }}</p>
                <p>Access Level</p>
              </div>
              <div class="mr-3">
                <VBtn
                  block
                  depressed
                  large
                  class="ma-0 btnDesign"
                  color="primary"
                  @click="goTo(data.link)"
                >
                  Explore
                </VBtn>
              </div>
            </section>
          </VCard>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data: () => ({
    managerCard: [
      {
        title: 'Student',
        accessLevel: 'Manager',
        link: 'student',
        img: 'student',
      },
      {
        title: 'Receipt',
        accessLevel: 'Manager',
        link: 'receipt',
        img: 'receipt',
      },
    ],
    teacherCard: [
      {
        title: 'Attendance',
        accessLevel: 'Teacher',
        link: 'attendance',
        img: 'attendance',
      },
      {
        title: 'Timetable',
        accessLevel: 'Teacher',
        link: 'timetable',
        img: 'timetable',
      },
      {
        title: 'Note',
        accessLevel: 'Teacher',
        link: 'note',
        img: 'note',
      },
      {
        title: 'Result',
        accessLevel: 'Teacher',
        link: 'result',
        img: 'result',
      },
    ],
    adminCard: [
      {
        title: 'Student',
        accessLevel: 'Manager',
        link: 'student',
        img: 'student',
      },
      {
        title: 'Receipt',
        accessLevel: 'Manager',
        link: 'receipt',
        img: 'receipt',
      },
      {
        title: 'Result',
        accessLevel: 'Teacher',
        link: 'result',
        img: 'result',
      },
      {
        title: 'Attendance',
        accessLevel: 'Teacher',
        link: 'attendance',
        img: 'attendance',
      },
      {
        title: 'Message',
        accessLevel: 'Admin',
        link: 'message',
        img: 'message',
      },
      {
        title: 'Note',
        accessLevel: 'Teacher',
        link: 'note',
        img: 'note',
      },
      {
        title: 'Timetable',
        accessLevel: 'Teacher',
        link: 'timetable',
        img: 'timetable',
      },
      {
        title: 'Settings',
        accessLevel: 'Admin',
        link: 'settings',
        img: 'settings',
      },
    ],
  }),
  computed: {
    userRole() {
      return this.$store.state.currentUserType.role;
    },
  },
  methods: {
    goTo(v) {
      setTimeout(() => {
        this.$router.push(`/${v}`);
      }, 300);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/dashboard';
</style>
